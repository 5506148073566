var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(!_vm.checkEmailTemplateExists())?_c('div',[_vm._v("Please Select Template")]):_c('div',[(!_vm.showFields.emailView)?_c('div',{staticClass:"size-selector"},[_c('label',{attrs:{"for":"cardHeight"}},[_vm._v("Card Height:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cardHeight),expression:"cardHeight"}],attrs:{"type":"range","id":"cardHeight","min":"100","max":"700"},domProps:{"value":(_vm.cardHeight)},on:{"__r":function($event){_vm.cardHeight=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.cardHeight)+"px")])]):_vm._e(),_c('el-row',{staticClass:"email-view-container",staticStyle:{"display":"flex","flex-direction":"row","margin-top":"10px"}},[_c('el-col',{staticClass:"sidebar email-view-col",staticStyle:{"overflow-y":"auto"}},[_c('el-scrollbar',{attrs:{"wrap-style":"min-height: 70vh;overflow-y: auto;"}},[_c('div',{staticClass:"card-summary"},_vm._l((!_vm.searchQuery
                  ? _vm.parsedData
                  : this.searchData),function(template,index){return _c('div',{key:index,staticClass:"summary",style:({
                  backgroundColor:
                    _vm.selectedRecord._id === template._id ||
                    _vm.isSelectedInBulk(template._id)
                      ? '#f5f7fa'
                      : 'white',

                  color:
                    _vm.selectedRecord._id === template._id ||
                    _vm.isSelectedInBulk(template._id)
                      ? 'var(--primary-color)'
                      : '',

                  borderLeft:
                    _vm.selectedRecord._id === template._id
                      ? '3px solid var(--primary-color)'
                      : '',
                })},[_c('div',[_c('el-checkbox',{staticStyle:{"float":"left","color":"var(--primary-color)"},attrs:{"value":_vm.isSelectedInBulk(template._id)},on:{"change":function($event){return _vm.onCheckboxChange(template, $event)}}})],1),_c('div',{staticClass:"ml-2",on:{"click":function($event){return _vm.selectedCard(template)}}},[(template.field === 'title')?_c('div',[_c('span',[_vm._v("Title:")]),_vm._v(_vm._s(template.fieldValue)+" ")]):(template.field === 'description')?_c('div',[_c('span',[_vm._v("Description:")]),_vm._v(_vm._s(template.fieldValue)+" ")]):_c('div',[(
                        _vm.getFieldKey('title') && _vm.getFieldValue('title', template)
                      )?_c('div',{style:(_vm.getFieldInStepFormat(_vm.getFieldKey('title', template)))},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("title"))+" ")]),_vm._v(": "),_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", template),140, "...")))])]):_vm._e(),(
                        _vm.getFieldKey('description') &&
                        _vm.getFieldValue('description', template)
                      )?_c('div',{style:(_vm.getFieldInStepFormat(
                          _vm.getFieldKey('description', template)
                        ))},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("description"))+" ")]),_vm._v(": "),_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("description", template),140, "...")))])]):_vm._e()])])])}),0)])],1),_c('el-col',{staticClass:"email-view-col",staticStyle:{"max-height":"60vh"}},[(!_vm.isEdit)?_c('div',{staticStyle:{"overflow-y":"hidden"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: 70vh"}},[(
                  _vm.selectedRecord &&
                  (_vm.searchQuery != '' || _vm.middleDivFilterData.length)
                )?_c('div',{staticClass:"cards"},_vm._l((_vm.searchQuery != ''
                    ? _vm.searchFilterData
                    : _vm.middleDivFilterData),function(row,index){return _c('div',{key:index,staticClass:"entity-card-data",staticStyle:{"overflow-x":"hidden"}},[(_vm.showFields.multiselectDisplay)?_c('div',{staticClass:"entity-data-card",on:{"dblclick":function($event){return _vm.cellDoubleClicked()}}},[_c('div',[(!_vm.isEdit)?_c('div',[(_vm.isDragging)?_c('i',{staticClass:"tick-icon",staticStyle:{"float":"right"},on:{"click":_vm.saveData}},[_vm._v("✔️")]):_vm._e(),_c('draggable',{on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false}},model:{value:(_vm.showFields.multiselectDisplay),callback:function ($$v) {_vm.$set(_vm.showFields, "multiselectDisplay", $$v)},expression:"showFields.multiselectDisplay"}},_vm._l((_vm.showFields.multiselectDisplay),function(data,index){return _c('div',{key:index,staticClass:"no-edit"},[_c('el-tooltip',{attrs:{"content":" You can drag the fields to re-arrange the order","placement":"top"}},[_c('div',{staticClass:"icon-container"},[_c('img',{staticClass:"draggable-icon",attrs:{"src":require("@/assets/draggable.svg"),"alt":"draggable-icon"}})])]),_c('div',{staticClass:"bold-key key-field"},[_vm._v(" "+_vm._s(_vm.getFieldLabel(data.split("#")[1]))+": ")]),_vm._l((_vm.filterData(row)),function(field){return _c('div',{key:field._id,staticClass:"value-field"},[(field.key === data)?_c('div',[(field.inputType === 'ENTITY')?_c('span',{staticStyle:{"word-break":"break-word","white-space":"normal","display":"block"}},[(
                                      row[
                                        field.key.split('#')[0] +
                                          '#' +
                                          field.key.split('#')[1]
                                      ] &&
                                      row[
                                        field.key.split('#')[0] +
                                          '#' +
                                          field.key.split('#')[1]
                                      ].length > 0
                                    )?_c('p',[_vm._v(" "+_vm._s(_vm.currentData[field.key.split("#")[0]][ `${field.key.split("#")[1]}/name` ])+" ")]):_c('p',[_vm._v("-----")])]):(field.inputType == 'CURRENCY')?_c('span',{staticStyle:{"word-break":"break-word","white-space":"normal","display":"block"}},[_vm._v(" "+_vm._s(_vm.currencySymbolObject[ _vm.currentData[field.key.split("#")[0]][ "currency_currency" ] ])+_vm._s(_vm.currentData[field.key.split("#")[0]][ field.key.split("#")[1] ])+" ")]):(field.inputType == 'DATE')?_c('span',{staticStyle:{"word-break":"break-word","white-space":"normal","display":"block"}},[_vm._v(" "+_vm._s(_vm.getFormattedDate( _vm.currentData[field.key.split("#")[0]][ field.key.split("#")[1] ] ))+" ")]):(field.inputType == 'DATE_TIME')?_c('span',{staticStyle:{"word-break":"break-word","white-space":"normal","display":"block"}},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( _vm.currentData[field.key.split("#")[0]][ field.key.split("#")[1] ] ))+" ")]):(
                                    _vm.currentData[field.key.split('#')[0]][
                                      field.key.split('#')[1]
                                    ] && field.inputType != 'ENTITY'
                                  )?_c('span',{staticStyle:{"word-break":"break-word","white-space":"normal","display":"block"}},[_vm._v(" "+_vm._s(_vm.currentData[field.key.split("#")[0]][ field.key.split("#")[1] ])+" ")]):_c('span',[_vm._v(" ---- ")])]):_vm._e()])})],2)}),0)],1):(_vm.isEdit)?_c('div',[(_vm.isEdit)?_c('div',{staticClass:"edit-cancel-div"},[_c('span',{staticClass:"edit-icons"},[_c('i',{staticClass:"tick-icon",on:{"click":function($event){return _vm.doubleClickUpdate(row)}}},[_vm._v("✔️")]),_c('i',{staticClass:"cross-icon",on:{"click":_vm.cancelEditAll}},[_vm._v("❌")])])]):_vm._e(),_vm._l((_vm.filterData(row)),function(field){return _c('div',{key:field.id,staticClass:"second-card"},[_c('span',{staticClass:"bold-key second-card-key"},[_vm._v(_vm._s(field.label.split("-")[1])+":")]),_c('div',[(field.inputType === 'AUTO_INCREMENT_NUMBER')?_c('span',[_vm._v(_vm._s(_vm.currentData[field.key.split("#")[0]][ field.key.split("#")[1] ]))]):_vm._e(),(
                                field.inputType !== 'SELECT' &&
                                field.inputType !== 'DATE_TIME' &&
                                field.inputType !== 'ENTITY' &&
                                field.inputType !== 'AUTO_INCREMENT_NUMBER' &&
                                field.inputType !== 'CHECKBOX'
                              )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.currentData[field.key.split('#')[0]][
                                  field.key.split('#')[1]
                                ]
                              ),expression:"\n                                currentData[field.key.split('#')[0]][\n                                  field.key.split('#')[1]\n                                ]\n                              "}],staticClass:"edit-input",domProps:{"value":(
                                _vm.currentData[field.key.split('#')[0]][
                                  field.key.split('#')[1]
                                ]
                              )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentData[field.key.split('#')[0]], 
                                  field.key.split('#')[1]
                                , $event.target.value)}}}):_vm._e(),(field.inputType === 'SELECT')?_c('el-select',{staticStyle:{"margin-left":"4px"},attrs:{"size":"small"},model:{value:(
                                _vm.currentData[field.key.split('#')[0]][
                                  field.key.split('#')[1]
                                ]
                              ),callback:function ($$v) {_vm.$set(_vm.currentData[field.key.split('#')[0]], 
                                  field.key.split('#')[1]
                                , $$v)},expression:"\n                                currentData[field.key.split('#')[0]][\n                                  field.key.split('#')[1]\n                                ]\n                              "}},_vm._l((field.options),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e(),(
                                field.inputType === 'DATE_TIME' &&
                                _vm.isCancelEditAll
                              )?_c('el-date-picker',{staticClass:"second-card",staticStyle:{"margin-left":"4px","width":"170px"},attrs:{"type":"datetime","placeholder":"Pick a date and time","format":"dd-MM-yyyy HH:mm:ss","value-format":"dd-MM-yyyy HH:mm:ss"},model:{value:(
                                _vm.currentData[field.key.split('#')[0]][
                                  field.key.split('#')[1]
                                ]
                              ),callback:function ($$v) {_vm.$set(_vm.currentData[field.key.split('#')[0]], 
                                  field.key.split('#')[1]
                                , $$v)},expression:"\n                                currentData[field.key.split('#')[0]][\n                                  field.key.split('#')[1]\n                                ]\n                              "}}):_vm._e(),(field.inputType === 'ENTITY')?_c('EntityExecute',{attrs:{"data":JSON.parse(
                                  JSON.stringify({
                                    ...field,
                                    key: field.key.split('#')[1],
                                  })
                                ),"form":_vm.currentData[field.key.split('#')[0]],"is-view":false,"hasLabel":false,"entityDataExists":true,"fieldsData":[]}}):_vm._e(),(field.inputType === 'CHECKBOX')?_c('div',[_c('el-checkbox',{attrs:{"disabled":_vm.quickUpdateFieldDisabled(
                                    field.key.split('#')[0],
                                    field.key.split('#')[1],
                                    _vm.currentEntity
                                  )},model:{value:(
                                  _vm.currentData[field.key.split('#')[0]][
                                    field.key.split('#')[1]
                                  ]
                                ),callback:function ($$v) {_vm.$set(_vm.currentData[field.key.split('#')[0]], 
                                    field.key.split('#')[1]
                                  , $$v)},expression:"\n                                  currentData[field.key.split('#')[0]][\n                                    field.key.split('#')[1]\n                                  ]\n                                "}})],1):_vm._e()],1)])})],2):_vm._e()]),_c('br'),_c('div',{staticStyle:{"margin-top":"20px"}},[(
                          _vm.showFields.action_group_button &&
                          _vm.showFields.action_group_button.length &&
                          !_vm.isEdit
                        )?_vm._l((_vm.showFields.action_group_button),function(action,index){return _c('div',{key:index},[(_vm.hasHash(action))?[_c('action-button-execute',{staticStyle:{"margin-top":"10px"},attrs:{"data":{
                                ..._vm.getActionButtonField(action),
                                parent_entityDataId: row._id,
                              },"form":row.entityData[action.split('#')[0]],"is-view":false,"hideStyles":true,"isView":false,"workflowDataId":row._id,"label":_vm.getActionButtonField(action, row._id).label,"entity_type":_vm.currentEntity.entity_type,"entityDataId":row._id,"entityId":_vm.currentEntity._id,"entityData":row,"checkIsDisabled":false,"selectedTableRows":[],"fieldsData":_vm.getCurrentTemplateFields(action.split('#')[0]),"selfTemplateId":action.split('#')[0]},on:{"actionExecuted":_vm.refreshCurrentPage}})]:[(
                                _vm.IframePermissions('VIEW', 'EntityGalleryView')
                              )?_c('a',{staticClass:"card-link",staticStyle:{"color":"rgb(64, 158, 255)","text-decoration":"none","padding":"10px","margin-left":"120px","margin-bottom":"5px"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.viewWorkUserData(row, action)}}},[_vm._v(" "+_vm._s(action)+" ")]):_vm._e()]],2)}):_vm._e(),(
                          !_vm.showFields.action_group_button ||
                          (!_vm.showFields.action_group_button.length && !_vm.isEdit)
                        )?[(_vm.IframePermissions('VIEW', 'EntityGalleryView'))?_c('el-link',{staticClass:"card-link",staticStyle:{"margin-left":"100px","margin-bottom":"10px"},attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.viewWorkUserData(row)}}},[_vm._v(" View details ")]):_vm._e()]:_vm._e()],2)]):_c('el-empty',{staticClass:"entity-data-card",attrs:{"image-size":100,"description":"Not configured"}})],1)}),0):_c('div',[_c('el-empty',{attrs:{"description":"No data"}})],1),(
                  Array.isArray(_vm.searchFilterData) &&
                  _vm.searchFilterData.length === 0 &&
                  _vm.searchQuery
                )?_c('div',{staticClass:"cards"},[_c('el-empty',{attrs:{"description":"No data"}})],1):_vm._e()])],1):_vm._e(),(_vm.isEdit)?_c('div',{staticStyle:{"overflow-y":"hidden"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[(
                  _vm.selectedRecord &&
                  (_vm.searchQuery != '' || _vm.middleDivFilterData.length)
                )?_c('div',{staticClass:"cards"},_vm._l((_vm.searchQuery != ''
                    ? _vm.searchFilterData
                    : _vm.middleDivFilterData),function(row,index){return _c('div',{key:index,staticClass:"entity-card-data",staticStyle:{"overflow-x":"hidden"}},[(_vm.showFields.multiselectDisplay)?_c('div',{staticClass:"entity-data-card",on:{"dblclick":function($event){return _vm.cellDoubleClicked()}}},[(_vm.isEdit)?_c('templateFieldsPreview',{attrs:{"openNew":false,"entityDataId":_vm.selectedRecord._id,"saveTemp":true,"getSuggestionFormData":_vm.getSuggestionFormData,"getEntityPermission":_vm.getEntityPermission,"templateData":_vm.currentEntity.templates[0].templateInfo,"template-data-id":_vm.currentEntity.templates[0].templateDataId,"isFromQuickAdd":true,"fromAddWidget":true,"currentMenu":null,"workflow-step-id":_vm.currentEntity.templates[0]._id,"has-next":false,"entityData":_vm.selectedRecord,"isExecute":true,"layout":'STANDARD',"isEdit":true,"currentEntity":_vm.currentEntity,"isEmailView":_vm.isEmailView,"currentStep":_vm.currentEntity.templates[0],"refreshKey":_vm.currentRefreshKey,"hideActions":false}}):_vm._e(),(_vm.isEdit)?_c('span',{staticClass:"dialog-footer float-right",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"scaling-button",on:{"click":_vm.handleClose}},[_vm._v("Cancel")]),[_c('el-button',{staticClass:"scaling-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addNewRelationship()}}},[_vm._v(_vm._s(_vm.isEdit ? "Update " : "Add ")+" "+_vm._s(_vm.currentEntity.name))])]],2):_vm._e()],1):_c('el-empty',{staticClass:"entity-data-card",attrs:{"image-size":100,"description":"Not configured"}})],1)}),0):_c('div',[_c('el-empty',{attrs:{"description":"No data"}})],1),(
                  Array.isArray(_vm.searchFilterData) &&
                  _vm.searchFilterData.length === 0 &&
                  _vm.searchQuery
                )?_c('div',{staticClass:"cards"},[_c('el-empty',{attrs:{"description":"No data"}})],1):_vm._e()])],1):_vm._e()]),_c('el-col',{staticClass:"email-view-col",staticStyle:{"width":"70vw","margin-right":"10px"}},_vm._l((_vm.searchQuery != ''
              ? _vm.searchFilterData
              : _vm.middleDivFilterData),function(row,index){return _c('div',{key:index,staticClass:"card-container p-1",staticStyle:{"max-height":"70vh","overflow-y":"scroll","overflow-x":"hidden","box-shadow":"0 2px 4px rgba(0, 0, 0, 0.1)","border-radius":"8px","transition":"transform 0.2s, box-shadow 0.2s","border":"1px solid slategray"},attrs:{"span":14}},[_c('div',{staticClass:"bold-key",staticStyle:{"margin":"4px","font-size":"20px","float":"left","max-width":"73%"}},[_vm._v(" "+_vm._s(row.entityData[row.templates_data?.[0]?.template_id]?.subject)+" ")]),(
                _vm.actionButtonFieldKeys &&
                _vm.actionButtonFieldKeys.length &&
                _vm.isRelationalDataFetched
              )?_c('div',_vm._l((_vm.actionButtonFieldKeys),function(actionKey){return _c('div',{key:actionKey,staticClass:"button-container pb-1"},[(
                    row.entityData &&
                    actionKey.split('#') &&
                    actionKey.split('#').length
                  )?_c('ActionButtonExecute',{attrs:{"data":_vm.getActionButtonField(actionKey, row._id),"form":row.entityData[actionKey.split('#')[0]],"is-view":false,"isView":false,"workflowDataId":row._id,"label":_vm.getActionButtonField(actionKey, row._id).label,"entity_type":_vm.currentEntity.entity_type,"entityDataId":row._id,"entityId":_vm.currentEntity._id,"entityData":row,"checkIsDisabled":false,"selectedTableRows":[],"fieldsData":_vm.getCurrentTemplateFields(actionKey.split('#')[0]),"selfTemplateId":actionKey.split('#')[0],"templateName":"templateName"},on:{"actionExecuted":_vm.refreshCurrentPage}}):_vm._e()],1)}),0):_vm._e(),_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[(_vm.getParsedData(row._id))?_c('div',{key:row._id,staticClass:"card"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getParsedData(row._id))}})]):_vm._e()])],1)}),0)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }